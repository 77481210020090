import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Container,
} from "reactstrap";
import Logo from "./images/titlelogo.png";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "./connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import metamax from "./images/metamaxicon.png";
import trustwall from "./images/trustwallet.png";
import bcwall from "./images/binanceicon.png";
import spwall from "./images/safewallet.png";
import wcwall from "./images/walletconnecticon.png";
import tpwall from "./images/tokenpocketicon.png";
import otwall from "./images/webwalleticon.png";
import { CommonDataContext } from "./CommonDataContext";
import "../App.css";

export default function Header() {
  const valContext = useContext(CommonDataContext);
  const { runCommand, setrunCommand } = valContext;
  const {
    library,
    account,
    active,
    deactivate,
    activate: activateNetwork,
  } = useWeb3React();
  const Web3 = require("web3");
  const [modalV, setmodalV] = useState(false);
  const [acctADDR, setacctADDR] = useState(localStorage.getItem("acct"));
  const [tradestate, setTradestate] = useState({ data: null, loading: true });
  const [Marketstate, setMarketstate] = useState({ data: null, loading: true });
  const [EGstate, setEGstate] = useState({ data: null, loading: true });

  const toggleModal = () => {
    setmodalV(!modalV);
  };
  const { activate } = useWeb3React();
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };
  const networks = {
    polygon: {
      chainId: `0x${Number(137).toString(16)}`,
      chainName: "Polygon Mainnet",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: ["https://polygon-rpc.com/"],
      blockExplorerUrls: ["https://polygonscan.com/"],
    },
    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: [
        "https://bsc-dataseed1.binance.org",
        "https://bsc-dataseed2.binance.org",
        "https://bsc-dataseed3.binance.org",
        "https://bsc-dataseed4.binance.org",
        "https://bsc-dataseed1.defibit.io",
        "https://bsc-dataseed2.defibit.io",
        "https://bsc-dataseed3.defibit.io",
        "https://bsc-dataseed4.defibit.io",
        "https://bsc-dataseed1.ninicoin.io",
        "https://bsc-dataseed2.ninicoin.io",
        "https://bsc-dataseed3.ninicoin.io",
        "https://bsc-dataseed4.ninicoin.io",
        "wss://bsc-ws-node.nariox.org",
      ],
      blockExplorerUrls: ["https://bscscan.com"],
    },
  };
  if (window.ethereum) {
    window.ethereum
      .request({
        method: "eth_chainId",
      })
      .then((res) => {
        if (res != "0x38")
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: 56,
                  chainName: "Binance Smart Chain",
                  nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  rpcUrls: ["https://bsc-dataseed1.binance.org/"],
                  blockExplorerUrls: ["https://bscscan.io/"],
                },
              ],
            })
            .then(() => {
              console.log("BSC Added");
            })
            .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }
  function addBscChain() {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 3000);
    }

    function handleEthereum() {
      const { ethereum } = window;
      if (ethereum) {
        console.log("Ethereum successfully detected!");
      } else {
        console.error("Ethereum not detected.");
      }
    }
    const BSC_MAINNET_PARAMS = {
      chainId: 56,
      chainName: "Binance Smart Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed1.binance.org/"],
      blockExplorerUrls: ["https://bscscan.io/"],
    };
  }
  const changeNetwork = async (wallet) => {
    try {
      addBscChain();
      if (wallet == "Metamask") {
        localStorage.setItem("wallettype", "Metamask");
        activate(connectors.injected);
        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
        fetchMarketData();
      }
      if (wallet == "WalletConnect") {
        localStorage.setItem("wallettype", "WalletConnect");
        let bridge = "https://bridge.walletconnect.org";
        let qrcode = true;
        let rpc = "https://bsc-dataseed1.binance.org";
        let chainId = 56;
        const provider = new WalletConnectConnector({
          rpc: {
            56: "https://bsc-dataseed1.binance.org",
            // ...
          },
          qrcodeModalOptions: {
            mobileLinks: ["trust"],
          },
          bridge: "https://bridge.walletconnect.org",
          qrcode: true,
          chainId: 56,
          pollingInterval: 8000,
        });
        activate(provider);

        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
      }
      if (wallet == "Coinbase") {
        localStorage.setItem("wallettype", "Coinbase");
        activate(connectors.coinbaseWallet);
        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
      }
      if (wallet == "Trustwallet") {
        localStorage.setItem("wallettype", "Trustwallet");
        activate(connectors.injected);
        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
      }
      if (wallet == "Others") {
        localStorage.setItem("wallettype", "Others");
        activate(connectors.injected);
        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
      }
      if (wallet == "TokenPocket") {
        localStorage.setItem("wallettype", "TokenPocket");
        activate(connectors.injected);
        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
      }
      if (wallet == "SafePal") {
        localStorage.setItem("wallettype", "SafePal");
        let bridge = "https://bridge.walletconnect.org";
        let qrcode = true;
        let rpc = "https://bsc-dataseed1.binance.org";
        let chainId = 56;
        const provider = new WalletConnectConnector({
          rpc: {
            56: "https://bsc-dataseed1.binance.org",
            // ...
          },
          qrcodeModalOptions: {
            mobileLinks: ["trust"],
          },
          bridge: "https://bridge.walletconnect.org",
          qrcode: true,
          chainId: 56,
          pollingInterval: 8000,
        });
        activate(provider);
        setmodalV(!modalV);
        window.localStorage.setItem("isWalletConnected", true);
        setacctADDR(account);
      }
    } catch (err) {
      console.log("ERROR");
    }
  };
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  useEffect(() => {
    if (account) {
      window.localStorage.setItem("isWalletConnected", true);
      setrunCommand(1);
      console.log("!~~~~Acccount");
      window.localStorage.setItem("acct", account);
      setacctADDR(account);
    } else {
      setacctADDR("");
      setrunCommand(0);
    }
  }, [account]);
  useEffect(() => {
    if (localStorage.getItem("isWalletConnected") == true) {
      localStorage.setItem("acct", acctADDR);
    }
  }, [acctADDR]);

  useEffect(() => {
    // fetchEgoldBalance();
    fetchMarketData();
    setacctADDR(localStorage.getItem("acct"));
    if (!active) {
      changeNetwork(localStorage.getItem("wallettype"));
      setmodalV(false);
    }
  }, []);

  const fetchMarketData = async () => {
    const urltd =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
    const marketurl =
      "https://egold-marketdata.herokuapp.com/v1/summary/get24MarketStat";
    //fetch 24 hour market data
    fetch(urltd)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("EG STATE", newData);
        setEGstate({ data: newData, loading: false });

        fetch(marketurl)
          .then(function (response) {
            return response.json();
          })
          .then(function (newData) {
            console.log("MRKET MDATA", newData);
            setMarketstate({ data: newData, loading: false });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const refreshState = () => {
    localStorage.setItem("provider", "");
    localStorage.setItem("acct", "");
    localStorage.setItem("isWalletConnected", false);
    localStorage.setItem("wallettype", "");
    setacctADDR("");
    deactivate();
  };

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  return (
    <>
      <nav className="navbar-custom navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a
            className="navbar-brand logo-image"
            href=""
            style={{ display: "inline-flex", cursor: "default" }}
          >
            <div id="logoo"></div>
          </a>
          <div style={{ float: "right", display: "flex", gap: "20px" }}>
            <div>
              {localStorage.getItem("acct") ? (
                <div
                  style={{ textAlign: "right", float: "right", width: "100%" }}
                  // sgjshs
                >
                  <div className="usraccmod" style={{ color: "white" }}>
                    Wallet :{" "}
                    {acctADDR
                      ? acctADDR.substring(0, 4) +
                        "...." +
                        acctADDR.substring(acctADDR.length - 3)
                      : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="nav-item">
              {localStorage.getItem("acct") ? (
                <button
                  className="btn-outline-sm discc"
                  style={{ margin: "0px" }}
                  onClick={() => {
                    refreshState();
                    console.log("ACCOUT AFTER", account);
                  }}
                >
                  Disconnect
                </button>
              ) : (
                <a
                  className="btn-outline-sm"
                  onClick={() => {
                    setmodalV(true);
                  }}
                  style={{ margin: "0px", cursor: "pointer" }}
                >
                  Connect Wallet
                </a>
              )}
            </div>
          </div>
        </div>
      </nav>
      {/* <Row md={12} style={{ background: "#0F1016", height: "65px" }}>
        <Col md={3} xs={3} sm={3}>
          <div
            style={{ display: "flex", marginTop: "10px", marginLeft: "10px" }}
          >
            <img src={Logo} alt="" height="40px" />
          </div>
        </Col>
        <Col md={6} xs={4} sm={4}></Col>
        <Col md={3} xs={5} sm={5}>
          <div style={{ float: "right", display: "flex" }}>
            <div>
              {localStorage.getItem("acct") ? (
                <div
                  style={{ textAlign: "right", float: "right", width: "100%" }}
                  // sgjshs
                >
                  <div className="usraccmod">
                    Wallet :{" "}
                    {acctADDR
                      ? acctADDR.substring(0, 4) +
                        "...." +
                        acctADDR.substring(acctADDR.length - 3)
                      : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              {localStorage.getItem("acct") ? (
                <Button
                  style={{
                    float: "right",
                    "margin-right": "10px",
                    border: "1px solid rgb(105, 105, 174)",
                    "background-color": "rgb(61 61 115)",
                    fontSize: "12px",
                    marginTop: "16px",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    refreshState();
                    console.log("ACCOUT AFTER", account);
                  }}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  className="connectbutton"
                  style={{
                    float: "right",
                    "margin-right": "10px",
                    border: "1px solid rgb(105, 105, 174)",
                    "background-color": "rgb(61 61 115)",
                    fontSize: "13px",
                    marginTop: "16px",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setmodalV(true);
                  }}
                >
                  CONNECT WALLET
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row> */}
      <Modal
        isOpen={modalV}
        toggle={toggleModal}
        size="md"
        backdrop="static"
        backdropClassName="connectmodalbg"
      >
        <ModalHeader
          toggle={toggleModal}
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "20px",
          }}
        >
          <h5
            style={{
              "font-size": "16px",
              textTransform: "uppercase",
              fontWeight: "700",
              color: "rgb(67 67 67)",
              margin: "0px 6px",
              marginBottom: "6px",
            }}
          >
            Connect Using :
          </h5>
        </ModalHeader>
        <ModalBody>
          <div>
            <ul className="wallet-lists">
              <li>
                <a
                  href="#"
                  onClick={() => {
                    changeNetwork("Metamask");
                    setProvider("injected");
                  }}
                >
                  Metamask <img src={metamax} />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    changeNetwork("WalletConnect");
                    setProvider("walletConnect");
                  }}
                >
                  WalletConnect <img src={wcwall} />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    changeNetwork("Trustwallet");
                    setProvider("injected");
                  }}
                >
                  TrustWallet <img src={trustwall} />
                </a>
              </li>
              <li>
                <a href="#">
                  Binance chain Wallet <img src={bcwall} />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    changeNetwork("SafePal");
                    setProvider("injected");
                  }}
                >
                  SafePal Wallet <img src={spwall} />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    changeNetwork("TokenPocket");
                    setProvider("injected");
                  }}
                >
                  TokenPocket <img src={tpwall} />
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => {
                    changeNetwork("Others");
                    setProvider("injected");
                  }}
                >
                  Other Web3 Wallets <img src={otwall} />
                </a>
              </li>
            </ul>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
