import React, { useState, useEffect } from "react";
import Loader from "./Loader";

function LiquidityData() {
  const [stagstate, setStagstate] = useState({
    data: null,
    error: false,
    loading: true,
  });

  useEffect(() => {
    initFtype();
  });

  function initFtype() {
    const url = "https://marketdata-api.herokuapp.com/v1/summary/tmoon/";
    console.log("fetching");

    //fetch trade data
    fetch(url)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setStagstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleFtype() {
    setStagstate({ loading: true });
    const url = "https://marketdata-api.herokuapp.com/v1/summary/tmoon/";
    console.log("fetching");

    //fetch trade data
    fetch(url)
      .then((data) => data.json())
      .then((obj) =>
        Object.keys(obj).map((key) => {
          let newData = obj[key];
          newData.key = key;
          return newData;
        })
      )
      .then((newData) =>
        setStagstate({ data: newData, error: false, loading: false })
      )
      .catch(function (error) {
        console.log(error);
      });
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <div className="tagtrade-content">
      <ul
        className="nav nav-tabs"
        style={{ marginTop: "0px", marginBottom: "5px" }}
      >
        <li className="nav-item" style={{ cursor: "pointer" }}>
          <a
            className="nav-link"
            style={{ background: "rgb(51 1 46)", color: "white" }}
            aria-current="page"
            onClick={handleFtype}
          >
            Claims, Staked & Unstaked
          </a>
        </li>
      </ul>
      <div className="body tdbdy" style={{ overflow: "auto" }}>
        <table id="squadTable" class="table">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>Type</th>
              <th>Amount</th>
              <th>Tx Hash</th>
            </tr>
          </thead>
          <tbody className="trbody">
            {stagstate.loading ? (
              <Loader />
            ) : (
              Object.keys(stagstate.data).map((k, i) => {
                let data = stagstate.data;
                return (
                  <tr
                    key={i}
                    className={
                      data[i].type == "C"
                        ? "claimtr"
                        : data[i].type == "S"
                        ? "buytr"
                        : "selltr"
                    }
                    style={{ textAlign: "center" }}
                  >
                    <td style={{ textTransform: "capitalize" }}>
                      {data[i].type == "C"
                        ? "CLAIM"
                        : data[i].type == "S"
                        ? "STAKE"
                        : "UNSTAKE"}
                    </td>
                    <td>
                      {parseFloat(data[i].amount).toFixed(3)}{" "}
                      {data[i].type == "C" ? "TAG" : "TMOON"}
                    </td>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://bscscan.com/tx/" + data[i].tx}
                      >
                        {data[i].tx.slice(0, 8)}
                      </a>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LiquidityData;
