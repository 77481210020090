import React, { useState, useEffect, useRef, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import Loader from "./Loader";
import { Store } from "react-notifications-component";
import ls from "local-storage";
import abiVal from "./data/dataval.json";
import { CommonDataContext } from "./CommonDataContext";
import TmoonData from "./TmoonData";

const Web3 = require("web3");
const web3 = new Web3("https://bsc-dataseed3.binance.org/");

const CONTRACT_ADDRESS = abiVal.stakingcontract;
const CONTRACT_ABI = abiVal.stakingabi;

const v1CONTRACT_ADDRESS = abiVal.v1stakingcontract;
const v1CONTRACT_ABI = abiVal.v1stakingabi;

const CLAIMCONTRACT_ADDRESS = abiVal.claimingcontract;
const CLAIMCONTRACT_ABI = abiVal.claimingabi;

const POOLTOKEN_ABI = abiVal.tokenabi;

const dpblock = 24309045;

function PoolFunctions() {
  const {
    library,
    account,
    active,
    deactivate,
    activate: activateNetwork,
  } = useWeb3React();

  async function addTokenFunction(tokenAddr) {
    try {
      const wasAdded = await library.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddr,
            symbol: "TMOON",
            decimals: 18,
          },
        },
      });

      if (wasAdded) {
        console.log("Added TMOON");
      } else {
        console.log("TMOON Coin has not been added");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const valContext = useContext(CommonDataContext);
  const { runCommand, setrunCommand } = valContext;

  const webb3 = new Web3(library);

  const useraddr = ls.get("acct");

  const contractInstanceread = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  const v1contractInstanceread = new web3.eth.Contract(
    v1CONTRACT_ABI,
    v1CONTRACT_ADDRESS
  );
  const claimcontractInstanceread = new web3.eth.Contract(
    CLAIMCONTRACT_ABI,
    CLAIMCONTRACT_ADDRESS
  );

  const contractInstancewrite = new webb3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  const v1contractInstancewrite = new webb3.eth.Contract(
    v1CONTRACT_ABI,
    v1CONTRACT_ADDRESS
  );
  const claimcontractInstancewrite = new webb3.eth.Contract(
    CLAIMCONTRACT_ABI,
    CLAIMCONTRACT_ADDRESS
  );

  const [tagmined, setTagmined] = useState("... ");
  const [tagcbal, setTagcbal] = useState("... ");
  const [blockmined, setBlockmined] = useState("... ");
  const [tmoonstaked, setTmoonstaked] = useState("... ");
  const [tokenbalance, setTokenbalance] = useState("... ");
  const [v1tokenbalance, setv1Tokenbalance] = useState("... ");
  const [userbalance, setUserbalance] = useState("... ");
  const [poolpercent, setPoolpercent] = useState("... ");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [isApproved, setisApproved] = useState(false);
  const [poolshare, setPoolshare] = useState("... ");
  const [cooldown, setCooldown] = useState(0);
  const [cooldowncheck, setCooldowncheck] = useState(0);
  const [cooldownblock, setCooldownblock] = useState("... ");
  const [cooldownpercent, setCooldownpercent] = useState(0);
  const [error, setError] = useState(0);
  const [remainbk, setRemainbk] = useState(0);
  const [val, setVal] = useState("");
  const [dval, setdVal] = useState("");
  const [claimbool, setClaimbool] = useState({ bool: true, loading: false });

  useEffect(() => {
    if (runCommand == 0) {
      defInit();
      setTokenbalance("... ");
      setUserbalance("... ");
      setPoolpercent("... ");
      setisApproved(false);
      setPoolshare("... ");
      setCooldown(0);
      setCooldowncheck(0);
      setCooldownblock("... ");
      setCooldownpercent(0);
      setRemainbk(0);
      setVal("");
      setClaimbool({ bool: true, loading: false });
    } else getInit();
  }, [runCommand]);

  const defInit = async () => {
    console.log("Init run");

    //to fetch tag mined
    await contractInstanceread.methods
      .totalRewards()
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setTagmined(noround(parseFloat(web3.utils.fromWei(res), "ether"), 3));
      });

    //to fetch blocks mined
    const currentbk = await web3.eth.getBlockNumber();
    setBlockmined(currentbk - dpblock);

    //to fetch tmoon staked
    await contractInstanceread.methods
      .totalSupply()
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setTmoonstaked(
          noround(parseFloat(web3.utils.fromWei(res), "ether"), 3)
        );
      });

    const tcontractInstancee = new web3.eth.Contract(
      POOLTOKEN_ABI,
      "0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5"
    );
    await tcontractInstancee.methods
      .balanceOf(CONTRACT_ADDRESS)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setTagcbal(noround(parseFloat(web3.utils.fromWei(res), "ether"), 3));
      });
  };

  const getInit = async () => {
    setClaimbool({ bool: false, loading: true });
    console.log("Init run");
    console.log("hereee", useraddr);

    //to fetch tag mined
    await contractInstanceread.methods
      .totalRewards()
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setTagmined(noround(parseFloat(web3.utils.fromWei(res), "ether"), 3));
      });

    //to fetch blocks mined

    const currentbk = await web3.eth.getBlockNumber();
    setBlockmined(currentbk - dpblock);

    //to fetch tmoon staked
    await contractInstanceread.methods
      .totalSupply()
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setTmoonstaked(
          noround(parseFloat(web3.utils.fromWei(res), "ether"), 3)
        );
      });

    //to get if user claimed v1

    await claimcontractInstanceread.methods
      .isClaimed(useraddr)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setClaimbool({ bool: res, loading: false });
      });

    //to get user last block

    await contractInstanceread.methods
      .depositBlock(useraddr)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        if (res == 0) {
          console.log("No deposit");
          return;
        } else {
          const userlb = parseInt(res);
          console.log("userlb-", userlb);
          const currentbk = await web3.eth.getBlockNumber();
          console.log("currentbk-", currentbk);
          await contractInstanceread.methods
            .fetchCooldown()
            .call(async function (err, res) {
              if (err) {
                console.log("An error occured", err);
                return;
              }
              const cd = parseInt(res);
              const percnt = (currentbk - userlb) / cd;
              const rmnbk = cd - (currentbk - userlb);
              console.log("cooldown = ", cd);
              console.log("percent = ", percnt);
              if (currentbk < userlb + cd) {
                setError(0);
                setCooldown(cd);
                setCooldowncheck(1);
                setCooldownblock(userlb + cd);
                setRemainbk(rmnbk);
                setCooldownpercent(percnt * 100);
              } else {
                setCooldowncheck(0);
                setError(1);
                setCooldownblock("... ");
              }
            });
        }
      });
    //to get balance

    console.log("hereee", useraddr);
    await contractInstanceread.methods
      .PoolToken()
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        const contractInstancee = new web3.eth.Contract(POOLTOKEN_ABI, res);
        await contractInstancee.methods
          .balanceOf(useraddr)
          .call(async function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            setUserbalance(
              noround(parseFloat(web3.utils.fromWei(res), "ether"), 3)
            );

            //To get pool token symbol
            await contractInstancee.methods
              .symbol()
              .call(async function (err, res) {
                if (err) {
                  console.log("An error occured", err);
                  return;
                }
                settokenSymbol(" " + res);
              });
          });
      });

    //To get tag balance of staking contract

    const tcontractInstancee = new web3.eth.Contract(
      POOLTOKEN_ABI,
      "0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5"
    );
    await tcontractInstancee.methods
      .balanceOf(CONTRACT_ADDRESS)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setTagcbal(noround(parseFloat(web3.utils.fromWei(res), "ether"), 3));
      });

    //To get Token balance

    await contractInstanceread.methods
      .balanceOf(useraddr)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        var tokbal = web3.utils.fromWei(res);
        setTokenbalance(
          noround(parseFloat(web3.utils.fromWei(res), "ether"), 3)
        );

        //To get total staked

        await contractInstanceread.methods
          .totalSupply()
          .call(async function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            }
            var percent = (tokbal / web3.utils.fromWei(res)) * 100;
            console.log("percenttt", percent);
            console.log("percentttres", res);
            setPoolpercent(noround(percent, 8));
          });
      });
    //To get v1 Token balance

    await v1contractInstanceread.methods
      .balanceOf(useraddr)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        var tokbal = web3.utils.fromWei(res);
        setv1Tokenbalance(
          noround(parseFloat(web3.utils.fromWei(res), "ether"), 3)
        );
      });

    //to get pool share
    await contractInstanceread.methods
      .fetchRewards(useraddr)
      .call(function (err, res) {
        if (err) {
          setPoolshare(0);
          console.log("An error occured", err);
          return;
        }
        setPoolshare(noround(parseFloat(web3.utils.fromWei(res), "ether"), 4));
      });
  };

  //To approve all
  async function approvePooltoken() {
    if (val) {
      await contractInstanceread.methods
        .PoolToken()
        .call(async function (err, res) {
          if (err) {
            console.log("An error occured", err);
            return;
          }
          const contractInstancee = new webb3.eth.Contract(POOLTOKEN_ABI, res);
          await contractInstancee.methods
            .approve(CONTRACT_ADDRESS, web3.utils.toWei(val))
            .send({
              from: account,
            })
            .on("receipt", async function (hash) {
              setisApproved(true);
              //   setLoadinggaptag(false);

              Store.addNotification({
                id: "approvee-complete",
                title: "Approved",
                message: "Request was Approved",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                },
              });
            })
            .on("error", function (error, receipt) {
              console.log("error");
              Store.addNotification({
                id: "approvee-error",
                title: "Error",
                message: error.message,
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 8000,
                },
              });
            });
        });
    }
  }

  //To stake all
  async function stake() {
    console.log("valinwei", web3.utils.toWei(val));
    await contractInstancewrite.methods
      .stake(web3.utils.toWei(val))
      .send({
        from: account,
      })
      .on("receipt", async function (hash) {
        //   setLoadinggaptag(false);
        setisApproved(false);
        getInit();
        Store.addNotification({
          id: "approvee-complete",
          title: "Staked",
          message: "Stake was successful",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .on("error", function (error, receipt) {
        console.log("error");
        Store.addNotification({
          id: "approvee-error",
          title: "Error",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 8000,
          },
        });
      });
  }

  //To claim
  async function claim() {
    if (error == 1) {
      await contractInstancewrite.methods
        .claim()
        .send({
          from: account,
        })
        .on("receipt", async function (hash) {
          //   setLoadinggaptag(false);
          getInit();
          Store.addNotification({
            id: "approvee-complete",
            title: "Claimed",
            message: "Claim was successful",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });
        })
        .on("error", function (error, receipt) {
          console.log("error");
          Store.addNotification({
            id: "approvee-error",
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 8000,
            },
          });
        });
    } else {
      Store.addNotification({
        id: "approvee-error",
        title: "Sorry",
        message:
          "Please wait until your staking matures. Blocks remaining " +
          remainbk +
          " / " +
          cooldown,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 20000,
        },
      });
    }
  }

  //To unstake
  async function unstake() {
    if (error == 1) {
      if (dval) {
        await contractInstancewrite.methods
          .unstake(web3.utils.toWei(dval))
          .send({
            from: account,
            value: web3.utils.toWei("0.001"),
          })
          .on("receipt", async function (hash) {
            //   setLoadinggaptag(false);
            getInit();
            Store.addNotification({
              id: "approvee-complete",
              title: "Unstaked",
              message: "Unstake was successful",
              type: "success",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          })
          .on("error", function (error, receipt) {
            console.log("error");
            Store.addNotification({
              id: "approvee-error",
              title: "Error",
              message: error.message,
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 8000,
              },
            });
          });
      }
    } else {
      Store.addNotification({
        id: "approvee-error",
        title: "Sorry",
        message:
          "Please wait until your staking matures. Blocks remaining " +
          remainbk +
          " / " +
          cooldown,
        type: "danger",
        insert: "top",
        width: 1000,
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 20000,
        },
      });
    }
  }
  //To unstake v1
  async function unstakev1() {
    await claimcontractInstancewrite.methods
      .claim()
      .send({
        from: account,
      })
      .on("receipt", async function (hash) {
        //   setLoadinggaptag(false);
        getInit();
        Store.addNotification({
          id: "approvee-complete",
          title: "Unstaked",
          message: "Unstake was successful",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      })
      .on("error", function (error, receipt) {
        console.log("error");
        Store.addNotification({
          id: "approvee-error",
          title: "Error",
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 8000,
          },
        });
      });
  }

  function errornoti() {
    Store.addNotification({
      id: "approvee-error",
      title: "Sorry",
      message: "Your wallet is not connected",
      type: "danger",
      insert: "top",
      width: 1000,
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 8000,
      },
    });
  }

  function handlevalChange(x) {
    setVal(x);
  }

  function handledvalChange(x) {
    setdVal(x);
  }

  function handleMax() {
    var x = userbalance;
    setVal(x);
  }

  function handledMax() {
    var x = tokenbalance;
    setdVal(x);
  }

  //to not round values while showing balance
  function noround(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  //for delaying retreiving token balances during transaction processing
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000 && num < 1000000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num > 1000000000) {
      return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 4);
      return x; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {cooldowncheck == 1 && (
        <div className="flxrow">
          <div className="col-md-8" style={{ margin: "0 auto" }}>
            <div class="progress-outer">
              <h6
                style={{
                  color: "#bebebe",
                  textAlign: "center",
                  marginBottom: "25px",
                }}
              >
                Your stake has not yet matured. Please wait until blocknumber{" "}
                {cooldownblock} to start collecting yield.
              </h6>
              <div class="progress">
                <div
                  class="progress-bar progress-bar-info progress-bar-striped active"
                  style={{ width: cooldownpercent + "%" }}
                ></div>
                <div class="progress-value">{parseInt(cooldownpercent)}%</div>
              </div>
              <h6
                style={{
                  textAlign: "center",
                  color: "#c043d7",
                  marginTop: "15px",
                }}
              >
                Blocks remaining for stake maturity: {remainbk} / {cooldown}
              </h6>
            </div>
          </div>
        </div>
      )}

      <div className="flxrow">
        {/* ----SWAP---- */}
        <div className="flxcolumn">
          <div className="app-content trcontent" style={{ marginTop: "25px" }}>
            <div className="tagtrade-content" style={{ textAlign: "center" }}>
              <h3 className="heading">TMOON Staking</h3>
            </div>

            <div className="tagtrade-content">
              <h4
                className="headingin width-50"
                style={{
                  display: "inline-block",
                  borderBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                Your Yield : <br />
                {poolshare} TAG
              </h4>
              <h4
                className="headingin width-50"
                style={{
                  display: "inline-block",
                  borderBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                Your Deposit : <br />
                {tokenbalance}
                {tokenSymbol}
              </h4>
            </div>
            <div className="tagtrade-content">
              <h4
                className="headingin width-100"
                style={{
                  display: "inline-block",
                  borderBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                Your Pool Share : {poolpercent} %
              </h4>
            </div>
            {/* <h6>Balance HTAGs : 230</h6> */}
            <div
              className="body"
              style={{ marginTop: "10px", minHeight: "300px" }}
            >
              <div className="form-container" style={{ marginTop: "10px" }}>
                <div
                  className="field-container"
                  style={{
                    background: "rgb(40, 10, 49)",
                    padding: "12px",
                    marginTop: "12px",
                    textAlign: "left",
                  }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "3px" }}
                  >
                    Amount
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "2px", float: "right" }}
                  >
                    Balance : {userbalance}
                    {tokenSymbol}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="bnb"
                      className="inp"
                      maxLength="10"
                      type="text"
                      name="fromvalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                        color: "white",
                      }}
                      placeholder="0.0"
                      value={val}
                      onChange={(e) => handlevalChange(e.target.value)}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "101",
                      }}
                    >
                      <button
                        style={{
                          background: "transparent",
                          border: "0px",
                          height: "16px",
                          marginRight: "15px",
                          marginTop: "7px",
                          fontWeight: "700",
                          color: "#3fabe5",
                          cursor: "pointer",
                          outline: "0px",
                        }}
                        onClick={(e) => handleMax()}
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>

                <div className="field-container" style={{ marginTop: "15px" }}>
                  <div
                    className="reghashtag"
                    style={{
                      display: "inline-block",
                      width: "49%",
                      marginRight: "1%",
                    }}
                  >
                    <div className="form-group d-flex">
                      <button
                        className="swapbutton disnone"
                        disabled={isApproved}
                        onClick={active ? approvePooltoken : errornoti}
                      >
                        APPROVE
                        <Loader />
                      </button>
                    </div>
                  </div>
                  <div
                    className="reghashtag"
                    style={{
                      display: "inline-block",
                      width: "49%",
                      marginLeft: "1%",
                    }}
                  >
                    <div className="form-group d-flex">
                      <button
                        className="swapbutton disnone"
                        disabled={!isApproved}
                        onClick={stake}
                      >
                        STAKE {tokenSymbol}
                        <Loader />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="field-container"
                  style={{
                    background: "rgb(40, 10, 49)",
                    padding: "12px",
                    marginTop: "12px",
                    textAlign: "left",
                  }}
                >
                  <label
                    className="labl"
                    htmlFor="name"
                    style={{ padding: "5px" }}
                  >
                    Amount
                  </label>
                  <label
                    className="labl"
                    htmlFor="bal"
                    style={{ padding: "2px", float: "right" }}
                  >
                    Deposit : {tokenbalance}
                    {tokenSymbol}
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="bnb"
                      className="inp"
                      maxLength="10"
                      type="text"
                      name="fromvalue"
                      style={{
                        background: "transparent",
                        border: "0px",
                        outline: "none",
                        color: "white",
                      }}
                      placeholder="0.0"
                      value={dval}
                      onChange={(e) => handledvalChange(e.target.value)}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        position: "absolute",
                        right: "8px",
                        zIndex: "101",
                      }}
                    >
                      <button
                        style={{
                          background: "transparent",
                          border: "0px",
                          height: "16px",
                          marginRight: "15px",
                          marginTop: "7px",
                          fontWeight: "700",
                          color: "#3fabe5",
                          cursor: "pointer",
                          outline: "0px",
                        }}
                        onClick={(e) => handledMax()}
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>
                <div className="field-container" style={{ marginTop: "5px" }}>
                  <div
                    className="form-outline"
                    style={{
                      paddingTop: "20px",
                      borderTop: "solid 1px #eeeeee",
                    }}
                  >
                    <div
                      className="reghashtag"
                      style={{
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <div className="form-group d-flex">
                        <button
                          className="disnone unstaker"
                          onClick={active ? unstake : errornoti}
                        >
                          UNSTAKE {tokenSymbol}
                          <Loader />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="app-content trcontent"
            style={{
              marginTop: "33px",
              minHeight: "134px",
              marginBottom: "5%",
            }}
          >
            <div className="tagtrade-content">
              <h4
                className="headingin width-50"
                style={{
                  display: "inline-block",
                  borderBottom: "0px",
                  marginBottom: "0px",
                  borderTop: "0px",
                }}
              >
                Tagcoin Mined : <br />
                {numFormatter(tagmined)} TAG
              </h4>
              <h4
                className="headingin width-50"
                style={{
                  display: "inline-block",
                  borderBottom: "0px",
                  marginBottom: "0px",
                  borderTop: "0px",
                }}
              >
                Blocks Mined : <br />
                {blockmined}
              </h4>
            </div>
            <div className="tagtrade-content">
              <h4
                className="headingin width-50"
                style={{ display: "inline-block", borderBottom: "0px" }}
              >
                Tagcoin Withdrawn : <br />
                {noround(tagmined - tagcbal, 3)} TAG
              </h4>
              <h4
                className="headingin width-50"
                style={{ display: "inline-block", borderBottom: "0px" }}
              >
                TMOON Staked : <br />
                {numFormatter(tmoonstaked)} TMOON
              </h4>
            </div>
          </div>
        </div>

        {/* ----second column----  */}
        <div className="flxcolumn">
          <div className="app-content trcontent farmheight">
            <TmoonData />
          </div>
        </div>
      </div>

      {claimbool.loading ? (
        <Loader />
      ) : (
        !claimbool.bool && (
          <div className="flxrow">
            {/* ----SWAP---- */}
            <div className="flxcolumn">
              <div
                className="app-content trcontent"
                style={{ marginTop: "25px" }}
              >
                <div
                  className="tagtrade-content"
                  style={{ textAlign: "center" }}
                >
                  <h3 className="heading">V1 Staking</h3>
                </div>

                <div className="tagtrade-content">
                  <h4
                    className="headingin width-100"
                    style={{
                      display: "inline-block",
                      borderBottom: "0px",
                      borderTop: "solid 1px #eeeeee",
                      marginBottom: "0px",
                    }}
                  >
                    Your Deposit : <br />
                    {v1tokenbalance}
                    {tokenSymbol}
                  </h4>
                </div>
                {/* <h6>Balance HTAGs : 230</h6> */}
                <div
                  className="body"
                  style={{ marginTop: "10px", minHeight: "100px" }}
                >
                  <div className="form-container" style={{ marginTop: "10px" }}>
                    <div
                      className="field-container"
                      style={{ marginTop: "5px" }}
                    >
                      <div
                        className="form-outline"
                        style={{
                          paddingTop: "20px",
                          borderTop: "solid 1px #eeeeee",
                        }}
                      >
                        <div
                          className="reghashtag"
                          style={{
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <div className="form-group d-flex">
                            <button
                              className="disnone unstaker"
                              onClick={active ? unstakev1 : errornoti}
                            >
                              UNSTAKE {tokenSymbol}
                              <Loader />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="container"
                    style={{
                      marginTop: "10px",
                      border: "1px solid #444",
                      borderRadius: "10px",
                      padding: "12px 25px",
                    }}
                  >
                    <h4 style={{ fontWeight: "700", color: "#bbb" }}>RULES</h4>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        color: "#777",
                      }}
                    >
                      1. Please migrate your coins from old staking contract to
                      new
                      <br />
                      2. The mining will activate once atleast 65% of token
                      supply is staked in the new contract
                      <br />
                      3. Until 65% staking is reached pool will produce only
                      1000 tagcoin per day only
                      <br />
                      4. All outstanding funds plus newly mined tagcoins will be
                      added to new contract
                      <br />
                      5. 65% threshold ensures fair distribution for everyone
                      <br />
                      <br />
                      <a
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                        href="https://www.youtube.com/watch?v=mo6rHnDU8us"
                        target="_blank"
                      >
                        How it works ?
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <br />
      <br />
      <div className="container">
        <div className="row">
          <div
            class="col-lg-12"
            style={{
              margin: "10px auto",
              fontSize: "12px",
              textAlign: "center",
              color: "#fff",
            }}
          >
            TMOON v2 : 0xa9ff122f533c1abfa16f28e500f9ea2841e8102f <br />
            <p style={{ marginTop: "10px" }}>
              <button
                style={{
                  marginRight: "5px",
                }}
                class="btn btn-sm btn-primary discc"
                onClick={() => {
                  navigator.clipboard.writeText(
                    "0xa9ff122f533c1abfa16f28e500f9ea2841e8102f"
                  );
                }}
              >
                Copy
              </button>
              <button
                class="btn btn-sm btn-primary discc"
                onClick={() =>
                  addTokenFunction("0xa9ff122f533c1abfa16f28e500f9ea2841e8102f")
                }
              >
                Add TMOON to MetaMask
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default PoolFunctions;
