import React from "react";
import "./index.css";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ls from "local-storage";

import "./app/css/bootstrap.min.css";
import "./app/css/style.css";
import "./app/css/affStyle.css";
import Header from "./components/Header";
import PoolFunctions from "./components/poolfunctions";

import ErrorPage from "./components/ErrorPage";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { StrictMode } from "react";
import Web3 from "web3";
import CommonDataContextProvider from "./components/CommonDataContext";

require("dotenv").config();

//To disable log on production
// if (process.env.NODE_ENV === "production") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <PoolFunctions />,
    errorElement: <ErrorPage />,
  },
]);

const getLibrary = (provider) => {
  return new Web3(provider);
};
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <CommonDataContextProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className="App">
          <ReactNotifications />
          <Header />
          <RouterProvider router={router} />
        </div>
      </Web3ReactProvider>
    </CommonDataContextProvider>
  </StrictMode>
);
